import { VideoPlayer } from "@components/cms/multiple-media/VideoPlayer";
import type {
    IModuleImage,
    IModuleProductGridSlider,
    IModuleVideo,
} from "@contentful-api/types/contentful";
import { useListOfProductsQuery } from "@hooks/useListOfProductsQuery";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";
import { ModuleCreator } from "@lib/contentful/modules-renderer/ModuleCreator";
import { getImageAltTag } from "@lib/utils/attributesUtils";
import { ProductTile } from "@ui/components/commerce/product-tile/ProductTile";
import { ScrollableGrid } from "@ui/components/data-display/scrollable-grid/ScrollableGrid";
import { Skeleton } from "@ui/components/data-display/skeleton/Skeleton";
import { NextImage } from "@ui/components/media-and-icons/image/NextImage";
import { isEmpty } from "lodash-es";
import { useRouter } from "next/router";
import type { FC } from "react";

interface IModuleImageProps {
    module: IModuleImage;
}

type MediaModule = IModuleImage | IModuleVideo;

const ModuleImage: FC<IModuleImageProps> = ({ module }) => {
    const url = module?.fields?.image?.fields?.file?.url;
    const description = module?.fields?.image?.fields?.description;

    return (
        <NextImage
            key={1}
            src={url}
            alt={description}
            sizes={["100vw", "100vw", "50vw"]}
            objectFit="cover"
        />
    );
};

const modulesMap = {
    moduleImage: ModuleImage,
    moduleVideo: VideoPlayer,
};

const mediaModuleRenderer = (mediaModules: Array<MediaModule>) => {
    if (isEmpty(mediaModules)) return null;

    return mediaModules?.map((mediaModule, index) => {
        const id = mediaModule?.sys?.id;
        const type = mediaModule?.sys?.contentType?.sys?.id;

        const Component = modulesMap[type];

        return (
            <ModuleCreator
                module={mediaModule}
                index={index}
                type={type}
                component={Component}
                key={id}
            />
        );
    });
};

const productTilesRenderer = (products: ReturnType<typeof useListOfProductsQuery>) => {
    return products?.map((product) => {
        return (
            <ProductTile
                key={product?.key}
                productName={product?.productName}
                articleNumber={product?.articleNumber}
                colorCode={product?.colorCode}
                image={product?.image.url}
                imageLabel={product?.image.label}
                altTagPattern={getImageAltTag(product?.productName, product?.image.label)}
                productColors={product?.productColors}
                objectID={product?.objectID}
                locale={product?.locale}
                priceWithoutDiscount={product?.priceWithoutDiscount}
                priceWithDiscount={product?.priceWithDiscount}
                aspectRatioImage={["3/2"]}
                //TODO: update query to fetch percentage here
                discountPercentage={0}
                colorLabel={product?.colorLabel}
                imageBackgroundColor={product?.imageBackgroundColor}
            />
        );
    });
};

export const productsSkeletonRenderer = (products: string[]) =>
    products.map((product) => {
        return <Skeleton key={product} h="full" w="full" />;
    });

interface IProductGridSliderProps extends IModuleRenderedProps<IModuleProductGridSlider> {}

export const ProductGridSlider: FC<IProductGridSliderProps> = ({ module }) => {
    const topMediaModulesCollection = module?.fields?.topMediaModules;
    const bottomMediaModulesCollection = module?.fields?.bottomMediaModules;
    const topMediaModulesPosition = module?.fields?.topMediaModulesPosition;
    const bottomMediaModulesPosition = module?.fields?.bottomMediaModulesPosition;
    const backgroundColor = module?.fields?.backgroundColor;
    const products = module?.fields?.products;

    const { locale } = useRouter();

    const productsSkeleton = productsSkeletonRenderer(products);
    const fetchedProducts = useListOfProductsQuery({ products, locale, keepOrder: true });

    const topMediaModules = mediaModuleRenderer(topMediaModulesCollection);
    const bottomMediaModules = mediaModuleRenderer(bottomMediaModulesCollection);

    const productTiles = productTilesRenderer(fetchedProducts);

    const data = {
        topMediaModules,
        bottomMediaModules,
        topMediaModulesPosition,
        bottomMediaModulesPosition,
        products: productTiles || productsSkeleton,
    };

    return (
        <ScrollableGrid
            productHeight={"25rem"}
            data={data}
            backgroundColor={backgroundColor?.value}
            w="full"
        />
    );
};
