import { GridItem, Grid } from "@ui/components/layout/grid/Grid";
import { Link } from "@components/cms/link/Link";
import type { IModuleCardLinkCollection } from "@contentful-api/types/contentful";
import { H } from "@ui/components/content/heading/A11yHeading";
import { Text } from "@ui/components/content/text/Text";
import { Button } from "@ui/components/forms/button/Button";
import type { ICardProps } from "@ui/components/layout/card/Card";
import { Card } from "@ui/components/layout/card/Card";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { isEmpty } from "lodash-es";
import type { FC } from "react";
import { Fragment } from "react";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";

interface ITextLinksProps {
    links: IModuleCardLinkCollection["fields"]["links"];
}

const TextLinks: FC<ITextLinksProps> = ({ links }) => {
    return (
        <Flex wrap="wrap" gap="8" mt={[6, 6, 6]}>
            {links?.map((link) => {
                const label = link?.fields?.label;
                const description = link?.fields?.description;

                return (
                    <Grid
                        key={link?.sys?.id}
                        gap={2}
                        maxW={64}
                        minW={64}
                        templateColumns="repeat(3, 1fr)"
                        as={Link}
                        link={link}
                        textDecoration="none"
                        _hover={{
                            textDecoration: "none",
                        }}
                    >
                        <GridItem colSpan={3} display="flex" gap={3} alignItems="center">
                            <Text
                                fontWeight="semibold"
                                fontSize={[
                                    "mobileBodyTextNormal",
                                    "mobileBodyTextNormal",
                                    "desktopBodyTextNormal",
                                ]}
                            >
                                {label}
                            </Text>
                            <ECCOIcon name="arrow-right" size="2xs" />
                        </GridItem>

                        <GridItem colSpan={3}>
                            <Text
                                fontWeight="semibold"
                                fontSize={[
                                    "mobileBodyTextSmall",
                                    "mobileBodyTextSmall",
                                    "desktopBodyTextSmall",
                                ]}
                                color="black"
                                maxW="80%"
                            >
                                {description}
                            </Text>
                        </GridItem>
                    </Grid>
                );
            })}
        </Flex>
    );
};

interface IButtonLinksProps {
    links: IModuleCardLinkCollection["fields"]["links"];
}

const ButtonLinks: FC<IButtonLinksProps> = ({ links }) => {
    return (
        <Flex wrap="wrap" gap="4" mt={[6, 6, 6]}>
            {links?.map((link, i) => {
                const label = link?.fields?.label;

                return (
                    <Fragment key={`${link?.sys?.id} - ${i}`}>
                        {label && (
                            <Button
                                as={Link}
                                link={link}
                                variant="tertiary"
                                textDecoration="none"
                                _hover={{
                                    textDecoration: "none",
                                }}
                                px={6}
                                py={4}
                            >
                                <span>{label}</span>
                            </Button>
                        )}
                    </Fragment>
                );
            })}
        </Flex>
    );
};

const linksMap: Record<IModuleCardLinkCollection["fields"]["style"], FC<any>> = {
    text: TextLinks,
    button: ButtonLinks,
};

interface ICardLinkCollectionProps
    extends IModuleRenderedProps<IModuleCardLinkCollection>,
        ICardProps {}

export const CardLinkCollection: FC<ICardLinkCollectionProps> = ({ module, ...rest }) => {
    const header = module?.fields?.header;
    const description = module?.fields?.description;
    const style = module?.fields?.style;
    const links = module?.fields?.links;

    const Links = linksMap[style];

    return (
        <Card gap={[0, 0, 0]} {...rest}>
            {header && <H size="h5">{header}</H>}
            {description && (
                <Text mt={[4, 4, 4]} color="black" fontWeight="semibold">
                    {description}
                </Text>
            )}
            {!isEmpty(links) && <Links links={links} />}
        </Card>
    );
};
