import { SearchProductsHitsToCarousel } from "@components/commerce/product/product-carousel/SearchProductHitsToCarousel";
import type { IModuleCustomProductCarousel } from "@contentful-api/types/contentful";
import { AnalyticsListTypes } from "@lib/analytics/AnalyticsClient";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";
import { GetLiveUpdates } from "@lib/contentful/modules-renderer/ModulesRenderer";
import { isEmpty } from "lodash-es";
import { useRouter } from "next/router";
import type { FC } from "react";

interface ICustomProductCarouselProps extends IModuleRenderedProps<IModuleCustomProductCarousel> {}

export const CustomProductCarousel: FC<ICustomProductCarouselProps> = ({
    module,
    authorizationInvitation,
}) => {
    const { isPreview } = useRouter();
    const updatedData = isPreview
        ? (GetLiveUpdates(module) as IModuleCustomProductCarousel)
        : module;
    const header = updatedData?.fields?.header;
    const products = updatedData?.fields?.products;
    const assistiveLabel = updatedData?.fields?.assistiveLabel;
    const extraLink = updatedData?.fields?.extraLink;
    const extraLinkLabel = updatedData?.fields?.extraLinkLabel;
    const moduleId = updatedData?.sys.id;
    if (isEmpty(products)) {
        return null;
    }

    return (
        <SearchProductsHitsToCarousel
            products={products}
            headline={header}
            assistiveMessage={assistiveLabel}
            sortingOrder={products}
            carouselType={AnalyticsListTypes.custom}
            authorizationInvitation={authorizationInvitation}
            extraLink={extraLink}
            extraLinkLabel={extraLinkLabel}
            id={moduleId}
        />
    );
};

export default CustomProductCarousel;
