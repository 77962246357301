import { Button, useBreakpointValue } from "@chakra-ui/react";
import type { FC } from "react";
import { useInView } from "react-intersection-observer";
import { ReadMore } from "../../../components/content/read-more/ReadMore";
import { Text } from "../../content/text/Text";
import { Box } from "../../layout/box/Box";
import { Flex } from "../../layout/flex/Flex";
import { ECCOIcon } from "../../media-and-icons/ecco-icon/ECCOIcon";
import { NextImage } from "../../media-and-icons/image/NextImage";
import { READ_MORE_EXPANDED_CLASSNAME, imageWrapperStyles, styles } from "./StoryTileStyles";

interface IStoryTileProps {
    imageSrc: string;
    imageAlt: string;
    title?: string;
    description?: string;
    columns: number;
    hasLink: boolean;
    linkTitle?: string;
    onInView?: () => void;
    showMoreLabel?: string;
    showLessLabel?: string;
    ariaMore?: string;
    ariaLess?: string;
}

export const StoryTile: FC<IStoryTileProps> = ({
    imageSrc,
    imageAlt,
    title,
    description,
    columns,
    hasLink,
    linkTitle,
    onInView,
    showMoreLabel,
    showLessLabel,
    ariaLess = "",
    ariaMore = "",
}) => {
    const { ref } = useInView({
        threshold: 0.5,
        onChange: (inView) => {
            if (inView && onInView) {
                setTimeout(() => onInView(), 2000);
            }
        },
    });

    const onClickReadMore = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const isMobile = useBreakpointValue({ base: true, sm: false });
    const columnWidth = columns ? 100 / columns : 50;

    return (
        <Flex
            flexDirection="column"
            h="full"
            w="full"
            sx={imageWrapperStyles(hasLink)}
            ref={ref}
            test-id={123}
        >
            <Box position="relative" h={"100%"}>
                <NextImage
                    src={imageSrc}
                    alt={imageAlt && imageAlt.length > 0 ? imageAlt : title}
                    sizes={["100vw", "100vw", `${columnWidth}vw`]}
                    sx={{
                        aspectRatio: ["4/5"],
                    }}
                    fill={true}
                    objectFit={"cover"}
                />
                <Box
                    style={{
                        position: "absolute",
                        height: "100%",
                        top: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        width: "100%",
                    }}
                >
                    <Box sx={styles.boxWrapper}>
                        <Box sx={styles.gradientBox}>
                            {(title || description) && (
                                <Flex sx={styles.textWrapper}>
                                    <Box sx={{ mb: [6, 8] }}>
                                        <Text
                                            color={"white"}
                                            fontWeight={"bold"}
                                            fontSize={["mobileH1", "desktopH2", "desktopH2"]}
                                        >
                                            {title}
                                        </Text>
                                        {description && (
                                            <ReadMore
                                                ariaMore={ariaMore}
                                                ariaLess={ariaLess}
                                                sx={styles.readMore}
                                                onClick={onClickReadMore}
                                                showMoreLabel={showMoreLabel}
                                                showLessLabel={showLessLabel}
                                                collapsedHeight={isMobile ? "2.5rem" : "4rem"}
                                                zIndex={10}
                                                showButtonStyle={styles.showButton}
                                                text={description}
                                                textStyle={styles.description}
                                                expandedClassname={READ_MORE_EXPANDED_CLASSNAME}
                                            />
                                        )}
                                    </Box>
                                    {title && hasLink && (
                                        <Flex
                                            pt={["1", "2"]}
                                            alignItems="center"
                                            gap={"0.2"}
                                            width={"full"}
                                        >
                                            <Button
                                                sx={{
                                                    height: ["9.6", "10.4"],
                                                    px: ["3", "4"],
                                                    py: "3",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                                width={"full"}
                                                w="100%"
                                                rightIcon={
                                                    hasLink ? (
                                                        <ECCOIcon name="arrow-right" />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                variant={"standard"}
                                                fontSize={["mobileLinkL1", "desktopLinkL1"]}
                                                fontWeight="bold"
                                            >
                                                <span>{linkTitle ?? title}</span>
                                            </Button>
                                        </Flex>
                                    )}
                                </Flex>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};
