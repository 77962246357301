import { Link } from "@components/cms/link/Link";
import type { IModuleHeadline } from "@contentful-api/types/contentful";
import type { HeadingProps } from "@ui/components/content/heading/Heading";
import { H } from "@ui/components/content/heading/A11yHeading";
import { Button } from "@ui/components/forms/button/Button";
import { Box } from "@ui/components/layout/box/Box";
import type { FlexProps } from "@ui/components/layout/flex/Flex";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { isEmpty } from "lodash-es";
import type { FC } from "react";
import { renderRichTextToComponent } from "../rich-text-renderer/renderRichTextToComponent";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";

const headingSizeMap: Record<string, HeadingProps["as"]> = {
    small: "h4",
    medium: "h3",
    large: "h2",
    "extra large": "h1",
};

interface IHeadlineProps extends IModuleRenderedProps<IModuleHeadline>, FlexProps {
    isModule?: boolean;
}

export const Headline: FC<IHeadlineProps> = ({
    module,
    isLeftColumn,
    isRightColumn,
    isModule = true,
    ...rest
}) => {
    const title = module?.fields?.title;
    const description = module?.fields?.desc;
    const callToActions = module?.fields?.callToActions;
    const titleSize = module?.fields?.titleSize || "large";
    const textAlign = module?.fields?.textAlignment || "center";
    const alignmentMap = {
        left: "flex-start",
        center: "center",
        right: "flex-end",
    };

    return (
        <Flex
            w="full"
            flexDirection="column"
            textAlign={textAlign}
            gap={4}
            alignItems={["center", alignmentMap[textAlign]]}
            {...rest}
            p={isModule && [6, 16]}
            data-contentful-entry-id={module?.sys?.id}
            data-contentful-field-id={"title"}
        >
            {title && (
                <Box maxW={["100%", "50%"]}>
                    <H as={headingSizeMap[titleSize]} w={["full", "full"]} m="auto">
                        {title}
                    </H>
                </Box>
            )}
            {description && (
                <Box maxW={["100%", "50%"]}>
                    <Box
                        aria-label="heading description"
                        textAlign={["center", "unset"]}
                        w="full"
                        m="auto"
                    >
                        {renderRichTextToComponent(description)}
                    </Box>
                </Box>
            )}
            {!isEmpty(callToActions) && (
                <Flex mt={4} wrap="wrap" justifyContent="center" gap={0.2} w={["100%", "auto"]}>
                    {callToActions?.map((button, i) => {
                        return (
                            <Button
                                rightIcon={<ECCOIcon name="arrow-right" />}
                                as={Link}
                                link={button?.fields?.link}
                                key={`${button?.sys?.id} - ${i}`}
                                variant={"standard"}
                                textDecoration="none"
                                h={10}
                                w={["100%", "auto"]}
                                _hover={{
                                    textDecoration: "none",
                                }}
                            >
                                <span>{button?.fields?.label}</span>
                            </Button>
                        );
                    })}
                </Flex>
            )}
        </Flex>
    );
};
