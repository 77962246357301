import { isRobotNoFollow, isRobotNoIndex } from "@components/seo/utils";
import type { ISeoMetadata } from "@contentful-api/types/contentful";
import { getCanonicalUrl } from "@lib/utils/getCanonicalUrl";
import type { NextSeoProps } from "next-seo";
import { NextSeo } from "next-seo";

interface PageSeoProps extends NextSeoProps {
    seoModule: ISeoMetadata;
    pathName: string;
}

export const PageSeo = ({ seoModule, pathName, ...nextSeoProps }: PageSeoProps) => {
    const title = seoModule?.fields?.title;
    const canonical = seoModule?.fields?.canonical;
    const robotIndex = seoModule?.fields?.robotIndex;
    const description = seoModule?.fields?.description;
    const robotFollow = seoModule?.fields?.robotFollow;
    const openGraphImage = seoModule?.fields?.openGraphImage?.fields;
    const openGraphTitle = seoModule?.fields?.openGraphTitle;
    const openGraphDescription = seoModule?.fields?.openGraphDescription;

    return (
        <NextSeo
            {...nextSeoProps}
            title={title}
            description={description}
            openGraph={{
                type: "website",
                title: openGraphTitle ?? title,
                ...(openGraphDescription && { description: openGraphDescription }),
                ...(openGraphImage && {
                    images: [
                        {
                            url: openGraphImage?.file?.url,
                            alt: openGraphImage?.description,
                            width: openGraphImage?.file?.details?.image?.width,
                            height: openGraphImage?.file?.details?.image?.height,
                        },
                    ],
                }),
                url: canonical ?? getCanonicalUrl(pathName),
            }}
            canonical={canonical ?? getCanonicalUrl(pathName)}
            {...(isRobotNoFollow(robotFollow) && { nofollow: true })}
            {...(isRobotNoIndex(robotIndex) && { noindex: true })}
        />
    );
};
